import {useEffect, useRef, useState} from 'react';

import {useTheme} from 'styled-components';

/**
 * Provides functionality to manage header behavior and styling based on its visibility within the viewport.
 * The `useHeader` hook leverages the Intersection Observer API to monitor the position of the header element
 * and applies a 'stuck' class when the header is not fully visible (i.e., when it's partially scrolled out of view).
 * This can be particularly useful for changing the appearance or behavior of the header based on its visibility,
 * such as applying a shadow or changing its background color when it becomes 'stuck' at the top of the viewport.
 * The hook returns a reference to the header element (`headRef`), which should be attached to the header component
 * in the JSX. This allows the hook to observe changes to the header's intersection with the viewport and adjust its
 * class list accordingly.
 *
 * @returns An object containing `headRef`, a ref object that should be attached to the header element to observe its visibility.
 *
 * @example
 * ```tsx
 * const {headRef} = useHeader();
 *
 * return (
 *   <header ref={headRef}>My Header</header>
 * );
 * ```
 */
export const useHeader = () => {
    const theme = useTheme();
    const [isStuck, setIsStuck] = useState(false);
    const headRef = useRef<HTMLHeadingElement>(null);
    const observerRef = useRef<IntersectionObserver>();

    useEffect(() => {
        if (!observerRef.current) {
            observerRef.current = new IntersectionObserver(
                ([e]) => {
                    e.target.classList.toggle('stuck', e.intersectionRatio < 1);

                    if (e.intersectionRatio < 1) {
                        setIsStuck(true);
                    } else {
                        setIsStuck(false);
                    }
                },
                {threshold: [1]}
            );
        }

        const {current: observer} = observerRef;

        observer.observe(document.querySelector('header') as Element);

        return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [observerRef.current, setIsStuck]);

    return {
        headRef,
        isStuck,
        theme
    };
};