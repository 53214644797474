import {media} from '@nfq/react-grid';
import styled from 'styled-components';

import {fontSize, fontWeight, lineHeight} from './utils';
import type {BaseColors, DerivedColors} from 'UI/utils/globalStyles';
import {chooseTheme} from 'UI/utils/helpers';

import type {FontSizes} from './utils';
import type {darken, lighten, translucify} from '@nfq/react-grid';
import type {DefaultTheme} from 'styled-components';

interface AlignmentProps {
    /**
     * The text alignment of the component.
     */
    $align?: 'center' | 'left' | 'right';
}

/**
 * A very large heading component for impactful visual presentation, especially suited for key visual elements.
 * This component stands out due to its significant font size, making it ideal for titles that require emphasis.
 * It automatically adjusts its font size for medium and larger devices to maintain visual hierarchy and readability.
 *
 * @param props        The props of the component.
 * @param props.$align Specifies the text alignment within the paragraph, providing layout flexibility and enhancing readability.
 * @param props.theme  Access to the application's theme, allowing the component to use theme-specific fonts and potentially other style settings.
 * @returns A styled h1 component with customizable alignment.
 *
 * @example
 * ```tsx
 * const App = () => {
 *     return <H1XXXL $align="center">Hello, World!</H1XXXL>;
 * };
 * ```
 */
export const H1XXXL = styled.h1<AlignmentProps>`
    font-size: 16rem;
    font-weight: 700;
    line-height: 1;
    margin: 0;
    text-align: ${({$align}) => $align};

    ${media('md')} {
        font-size: 26rem;
    }
`;

H1XXXL.defaultProps = {$align: 'left'};

/**
 * A large heading component designed for prominent section titles or important content highlights.
 * Its size and styling command attention while maintaining a balance with other page elements.
 * The font size increases for medium devices, ensuring that the heading remains prominent on all screen sizes.
 *
 * @param props        The props of the component.
 * @param props.$align Specifies the text alignment within the paragraph, providing layout flexibility and enhancing readability.
 * @param props.theme  Access to the application's theme, allowing the component to use theme-specific fonts and potentially other style settings.
 * @returns A styled h1 component with customizable alignment.
 *
 * @example
 * ```tsx
 * const App = () => {
 *     return <H1XXL $align="center">Hello, World!</H1XXL>;
 * };
 * ```
 */
export const H1XXL = styled(H1XXXL)`
    font-size: 10rem;
    font-weight: 500;
    letter-spacing: 2px;
    line-height: 1.2;

    ${media('md')} {
        font-size: 20rem;
        letter-spacing: 4px;
    }
`;

H1XXL.defaultProps = {$align: 'left'};

/**
 * An extra-large heading component that offers a bold visual statement without overwhelming the layout.
 * It is versatile and can be used for main titles in smaller sections or for significant statements.
 * The component's font size is responsive, adjusting for larger screens to enhance visibility.
 *
 * @param props        The props of the component.
 * @param props.$align Specifies the text alignment within the paragraph, providing layout flexibility and enhancing readability.
 * @param props.theme  Access to the application's theme, allowing the component to use theme-specific fonts and potentially other style settings.
 * @returns A styled h1 component with customizable alignment.
 *
 * @example
 * ```tsx
 * const App = () => {
 *     return <H1XL $align="center">Hello, World!</H1XL>;
 * };
 * ```
 */
export const H1XL = styled(H1XXXL)`
    font-size: 4.4rem;
    font-weight: 500;
    line-height: 1.2;

    ${media('md')} {
        font-size: 9.6rem;
    }
`;

H1XL.defaultProps = {$align: 'left'};

/**
 * The standard large heading component, providing a strong typographic hierarchy for titles and headings.
 * This component is suitable for primary section headers, offering clear distinction from other textual elements.
 * It features responsive font sizing to adapt to varying screen sizes, maintaining legibility and impact.
 *
 * @param props        The props of the component.
 * @param props.$align Specifies the text alignment within the paragraph, providing layout flexibility and enhancing readability.
 * @param props.theme  Access to the application's theme, allowing the component to use theme-specific fonts and potentially other style settings.
 * @returns A styled h1 component with customizable alignment.
 *
 * @example
 * ```tsx
 * const App = () => {
 *     return <H1 $align="center">Hello, World!</H1>;
 * };
 * ```
 */
export const H1 = styled(H1XXXL)`
    font-size: 4rem;
    font-weight: 500;
    line-height: 1.2;

    ${media('md')} {
        font-size: 8rem;
    }
`;

H1.defaultProps = {$align: 'left'};

/**
 * A medium-sized heading component, ideal for secondary titles or subsection headings, offering a step down from the primary heading size.
 * The H2 component supports the organization of content with a clear hierarchy, making it easier for users to navigate and understand the structure.
 * It includes responsive adjustments for medium devices, ensuring appropriate scaling and visibility.
 *
 * @param props        The props of the component.
 * @param props.$align Specifies the text alignment within the paragraph, providing layout flexibility and enhancing readability.
 * @param props.theme  Access to the application's theme, allowing the component to use theme-specific fonts and potentially other style settings.
 * @returns A styled h2 component with customizable alignment.
 *
 * @example
 * ```tsx
 * const App = () => {
 *     return <H2 $align="center">Hello, World!</H2>;
 * };
 * ```
 */
export const H2 = styled.h2<AlignmentProps>`
    font-size: 3.6rem;
    font-weight: 500;
    line-height: 1.2;
    margin: 0;
    text-align: ${({$align}) => $align};

    ${media('md')} {
        font-size: 6.4rem;
    }
`;

H2.defaultProps = {$align: 'left'};

/**
 * A slightly smaller heading component than H2, suitable for tertiary titles or important content points within sections.
 * The H3 component aids in further dividing content into manageable and understandable segments for the audience.
 * Responsive design considerations ensure the component adjusts to different screen sizes, maintaining its role in the content hierarchy.
 *
 * @param props        The props of the component.
 * @param props.$align Specifies the text alignment within the paragraph, providing layout flexibility and enhancing readability.
 * @param props.theme  Access to the application's theme, allowing the component to use theme-specific fonts and potentially other style settings.
 * @returns A styled h3 component with customizable alignment.
 *
 * @example
 * ```tsx
 * const App = () => {
 *     return <H2 $align="center">Hello, World!</H2>;
 * };
 * ```
 */
export const H3 = styled.h3<AlignmentProps>`
    font-size: 3.2rem;
    font-weight: 500;
    line-height: 1.2;
    margin: 0;
    text-align: ${({$align}) => $align};

    ${media('md')} {
        font-size: 5.6rem;
    }
`;

H3.defaultProps = {$align: 'left'};

/**
 * Represents a modestly sized heading, effective for headings of individual content elements or smaller sections.
 * The H4 component is useful for titles that require distinction without dominating the visual hierarchy of the page.
 * It includes responsive font size adjustments to ensure effectiveness across a range of device sizes.
 *
 * @param props        The props of the component.
 * @param props.$align Specifies the text alignment within the paragraph, providing layout flexibility and enhancing readability.
 * @param props.theme  Access to the application's theme, allowing the component to use theme-specific fonts and potentially other style settings.
 * @returns A styled h4 component with customizable alignment.
 *
 * @example
 * ```tsx
 * const App = () => {
 *     return <H2 $align="center">Hello, World!</H2>;
 * };
 * ```
 */
export const H4 = styled.h4<AlignmentProps>`
    font-size: 2.8rem;
    font-weight: 500;
    line-height: 1.2;
    margin: 0;
    text-align: ${({$align}) => $align};

    ${media('md')} {
        font-size: 4.8rem;
    }
`;

H4.defaultProps = {$align: 'left'};

/**
 * A small heading component designed for less prominent headings or for categorizing groups of information.
 * The H5 component offers subtlety in its presentation, fitting into the content hierarchy without drawing excessive attention.
 * Responsive adjustments are included, allowing the component to scale appropriately on larger screens.
 *
 * @param props        The props of the component.
 * @param props.$align Specifies the text alignment within the paragraph, providing layout flexibility and enhancing readability.
 * @param props.theme  Access to the application's theme, allowing the component to use theme-specific fonts and potentially other style settings.
 * @returns A styled h5 component with customizable alignment.
 *
 * @example
 * ```tsx
 * const App = () => {
 *     return <H2 $align="center">Hello, World!</H2>;
 * };
 * ```
 */
export const H5 = styled.h5<AlignmentProps>`
    font-size: 2.6rem;
    font-weight: 500;
    line-height: 1.2;
    margin: 0;
    text-align: ${({$align}) => $align};

    ${media('md')} {
        font-size: 3.2rem;
    }
`;

H5.defaultProps = {$align: 'left'};

/**
 * The smallest heading component in the collection, suitable for minor section titles or descriptive headings within detailed content areas.
 * The H6 component provides a final level of hierarchy, organizing content into clearly defined sections or categories.
 * It is responsive, with font size adjustments for medium devices, ensuring its role within the content structure is maintained.
 *
 * @param props        The props of the component.
 * @param props.$align Specifies the text alignment within the paragraph, providing layout flexibility and enhancing readability.
 * @param props.theme  Access to the application's theme, allowing the component to use theme-specific fonts and potentially other style settings.
 * @returns A styled h6 component with customizable alignment.
 *
 * @example
 * ```tsx
 * const App = () => {
 *     return <H2 $align="center">Hello, World!</H2>;
 * };
 * ```
 */
export const H6 = styled.h6<AlignmentProps>`
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 1.2;
    margin: 0;
    text-align: ${({$align}) => $align};
`;

H6.defaultProps = {$align: 'left'};

interface PProps {
    /**
     * The text alignment of the component.
     */
    $align?: 'center' | 'left' | 'right';
    /**
     * Specifies the font size of the paragraph, chosen from predefined options.
     * This property directly impacts the readability and visual hierarchy of text content,
     * allowing for flexible adaptation to various design requirements.
     */
    $size?: FontSizes;
}

/**
 * A styled paragraph component that utilizes the Aktiv Grotesk font family, providing consistent typography throughout the application.
 * The component is highly customizable, allowing for different font sizes, font weights, and line heights based on the provided `$size` property.
 * It supports text alignment customization through the `$align` property, offering flexibility in how paragraph content is displayed within various contexts.
 * The margin is set to 0 by default to allow for explicit spacing control in the component's usage environment, ensuring that the component can be seamlessly integrated into any layout without unintended margins affecting the design.
 *
 * @param props        The props of the component.
 * @param props.$align Specifies the text alignment within the paragraph, providing layout flexibility and enhancing readability.
 * @param props.$size  Controls the font size, weight, and line height of the paragraph, enabling customization to fit the content's needs.
 * @param props.theme  Access to the application's theme, allowing the component to use theme-specific fonts and potentially other style settings.
 * @returns A styled paragraph component with customizable typography and alignment.
 *
 * @example
 * ```tsx
 * const App = () => {
 *    return <P $size="large" $align="center">Hello, World!</P>;
 * };
 * ```
 */
export const P = styled.p<PProps>`
    font-size: ${fontSize};
    font-weight: ${fontWeight};
    line-height: ${lineHeight};
    margin: 0;
    text-align: ${({$align}) => $align};
`;

P.defaultProps = {
    $align: 'left',
    $size: 'large'
};

export interface ColorProps {
    /**
     * The color value to be applied to the text content.
     * The possible colors are determined by the pallette defined in the theme.
     */
    $color: ReturnType<typeof darken<typeof BaseColors[keyof typeof BaseColors]>>
        | ReturnType<typeof lighten<typeof BaseColors[keyof typeof BaseColors]>>
        | ReturnType<typeof translucify<typeof BaseColors[keyof typeof BaseColors]>>
        | typeof BaseColors[keyof typeof BaseColors]
        | typeof DerivedColors[keyof typeof DerivedColors];
}

/**
 * The Color component is a styled `<span>` component.
 *
 * @param props        The props of the component.
 * @param props.$color The color value to be applied to the text content.
 * @returns            A React component.
 *
 * @example
 * ```tsx
 * const App = () => {
 *     const colors = useThemeColors();
 *
 *     return <Color $color={colors.primaryFontColor}>Hello, World!</Color>;
 * };
 * ```
 */
export const Color = styled.span<ColorProps>`
    color: ${({$color}) => $color};
    transition: color 0.2s ease-in-out;
`;

export interface ThemedColorProps {
    /**
     * The color value to be applied to the text content.
     * The possible colors are determined by the pallette defined in the theme.
     */
    $color: keyof DefaultTheme['colors'];
}

export const ThemedColor = styled.span<ThemedColorProps>`
    color: ${({$color, theme}) => chooseTheme($color)({
        $reactsToTheme: true,
        theme
    })};
    transition: color 0.2s ease-in-out;
    will-change: color;
`;