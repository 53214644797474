import type {MouseEvent} from 'react';
import React from 'react';

import {spacing} from '@nfq/react-grid';
import Link from 'next/link';
import styled from 'styled-components';

import {
    getButtonBackgroundColor,
    getButtonBorderColor,
    getButtonFontColor,
    getButtonHeight,
    getButtonWidth
} from './utils';
import type {ButtonPointer} from 'Images/icons';

import type {ButtonSize, ButtonVariant, ButtonWidth} from './utils';
import type {WithOnlyTextChild} from 'types/global';

type LinkRel = 'nofollow noindex noopener noreferrer' | 'nofollow noindex noopener' | 'nofollow noindex noreferrer' |
'nofollow noindex' | 'nofollow noopener noreferrer' | 'nofollow noopener' | 'nofollow noreferrer' | 'nofollow' |
'noindex noopener noreferrer' | 'noindex noopener' | 'noindex noreferrer' | 'noindex' | 'noopener noreferrer' |
'noopener' | 'noreferrer';

/**
 * Defines the base properties for a button or link component. This interface supports conditional types based on the component type.
 * It provides a flexible way to define properties common to both buttons and links, as well as properties specific to each.
 * This approach simplifies the creation of a component that can render either a button or a link based on the provided props.
 */
interface BaseProps<C extends 'button' | 'link'> {
    /**
     * Specifies the element type to render: 'button' for a `<button>` element or 'link' for an `<Link>` element.
     * - `'button'`: Render a button element.
     * - `'link'`: Render a next link element.
     *
     * @default 'button'
     */
    as: C;
    /**
     * Optional CSS class name to customize the appearance of the button or link.
     */
    className?: string;
    /**
     * An optional icon element to display alongside the button text.
     */
    icon?: typeof ButtonPointer;
    /**
     * If the component is disabled, it will not be clickable and will have a different visual appearance.
     */
    isDisabled?: boolean;
    /**
     * Inverts the button or link's color scheme, making the background dark and the text light.
     */
    isInverted: boolean;
    /**
     * Determines the size of the button or link, affecting its dimensions and spacing.
     * - `'medium'`: Render an medium button.
     * - `'large'`: Render an large button.
     *
     * @default 'large'
     */
    size: ButtonSize;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
    /**
     * Indicates the button's variant, affecting its visual depth or emphasis.
     * - `'primary'`: A button with a primary color and high emphasis.
     * - `'secondary'`: A button with a secondary color and medium emphasis.
     * - `'outlined'`: A button with a border and transparent background.
     *
     * @default 'primary'
     */
    variant: ButtonVariant;
    /**
     * Sets the width of the button or link, allowing for fixed or fluid sizing.
     * - `'auto'`: The button or link will expand to fit its content.
     * - `'full'`: The button or link will expand to fill its container.
     *
     * @default 'auto'
     */
    width: ButtonWidth;
}

/**
 * Extends `BaseProps` to include additional properties specific to either a button or a link. This type uses conditional types
 * based on the value of `as` to include or exclude properties accordingly, ensuring type safety and relevance of props based on the element type.
 */
type ButtonProps<C extends 'button' | 'link'> = BaseProps<C> & (C extends 'button' ? {
    /**
     * Indicates whether the link should react to the current theme, adjusting its appearance accordingly.
     */
    $reactsToTheme?: boolean;
    /**
     * Do not use as it is not applicable to buttons.
     */
    href?: never;
    /**
     * Optional click event handler.
     */
    onClick?(e: MouseEvent<HTMLButtonElement>): void;
    /**
     * Do not use as it is not applicable to buttons.
     */
    rel?: never;
    /**
     * Do not use as it is not applicable to buttons.
     */
    scroll?: never;
    /**
     * Do not use as it is not applicable to buttons.
     */
    target?: never;
    /**
     * Defines the button type.
     * - `'button'`: An normal button.
     * - `'reset'`: An reset button for forms.
     * - `'submit'`: An submit button for forms.
     *
     * @default 'button'
     */
    type: 'button' | 'reset' | 'submit';
} : C extends 'link' ? {
    /**
     * Indicates whether the link should react to the current theme, adjusting its appearance accordingly.
     */
    $reactsToTheme?: boolean;
    /**
     * URL for the link element to link to.
     */
    href: string;
    /**
     * Optional click event handler.
     */
    onClick?(e: MouseEvent): void;
    /**
     * Defines the relationship between the current document and the linked URL.
     * - `'nofollow'`: Indicates that the link is not endorsed by the original document's author.
     * - `'noindex'`: Indicates that the linked document should not be indexed by search engines.
     * - `'noopener'`: Ensures that the linked document does not have access to the originating document.
     * - `'noreferrer'`: Ensures that the linked document does not have access to the originating document and does not pass referrer information.
     * Also every combination of these values is possible.
     */
    rel?: LinkRel;
    /**
     * Specifies whether the link should scroll to the top when clicked.
     */
    scroll?: boolean;
    /**
     * Specifies where to open the linked URL.
     * - `'_blank'`: Opens the linked document in a new window or tab.
     * - `'_parent'`: Opens the linked document in the parent frame.
     * - `'_self'`: Opens the linked document in the same frame as it was clicked.
     * - `'_top'`: Opens the linked document in the full body of the window.
     */
    target?: '_blank' | '_parent' | '_self' | '_top';
    /**
     * Do not use as it is not applicable to links.
     */
    type?: never;
} : never);

/**
 * A versatile button component that supports both `<button>` and `<a>` (link) elements through a single unified interface.
 * It can be used in various parts of an application where user interaction is required. The component is highly customizable
 * with support for different sizes, styles, and variants. It also allows for additional HTML attributes specific to buttons and links.
 *
 * @param props                The component props.
 * @param props.as             Determines the component's base element type: 'button' for `<button>` or 'link' for `<a>`.
 * @param props.children       The content to be displayed within the button or link.
 * @param props.className      Optional CSS class name to add to the button or link element.
 * @param props.href           URL for the link element. This prop should only be used when `as='link'`.
 * @param props.isDisabled     If `true`, the button or link will be disabled and not clickable.
 * @param props.isInverted     Inverts the button or link's color scheme, making the background dark and the text light.
 * @param props.onClick        Optional click event handler. Applies to both button and link variants.
 * @param props.rel            Specifies the relationship between the current document and the linked document. Only applicable when `as='link'`.
 * @param props.size           Specifies the size of the button, affecting its padding and font size.
 * @param props.target         Specifies where to open the linked document. Only applicable when `as='link'`.
 * @param props.testId         A unique identifier for testing purposes.
 * @param props.type           Specifies the button type (`'button'`, `'reset'`, or `'submit'`). Only applicable when `as='button'`.
 * @param props.variant        The variant of the button, affecting its visual presentation.
 * @param props.width          Specifies the width of the button, allowing for fluid or fixed width.
 * @param props.icon           An optional icon element to display alongside the button text.
 * @param props.$reactsToTheme Indicates whether the button or link should react to the current theme, adjusting its appearance accordingly.
 * @param props.scroll         Specifies whether the link should scroll to the top.
 * @returns A button or link element styled according to the provided props.
 *
 * @example
 * ```tsx
 * // Using Button as a link
 * <Button
 *   as="link"
 *   href="https://example.com"
 *   size="medium"
 *   style="primary"
 *   variant="contained"
 *   width="wide"
 *   testId="link-button-example"
 * >
 *   Visit Example
 * </Button>
 *
 * // Using Button as a button
 * <Button
 *   as="button"
 *   onClick={() => console.log('Button clicked')}
 *   size="small"
 *   style="secondary"
 *   variant="outlined"
 *   width="auto"
 *   type="submit"
 *   testId="button-example"
 * >
 *   Submit
 * </Button>
 * ```
 */
const Button = <C extends 'button' | 'link' = 'button'>({
    $reactsToTheme,
    as,
    children,
    className,
    href,
    icon,
    isDisabled,
    isInverted,
    onClick,
    rel,
    scroll,
    size,
    target,
    testId,
    type,
    variant,
    width
}: WithOnlyTextChild<ButtonProps<C>>
) => {
    const Icon = icon;

    return (
        <Wrapper
            $isInverted={isInverted}
            $reactsToTheme={$reactsToTheme}
            $size={size}
            $variant={variant}
            $width={width}
            as={as === 'link' ? Link : 'button'}
            className={className}
            data-cy={testId}
            disabled={isDisabled}
            href={href}
            rel={rel}
            scroll={scroll}
            target={target}
            type={as === 'button' ? type : undefined}
            onClick={onClick}
        >
            {children}
            {(Icon) && (<Icon color1="currentColor" />)}
        </Wrapper>
    );
};

Button.displayName = 'Button';
Button.defaultProps = {
    as: 'button',
    isDisabled: false,
    isInverted: false,
    position: 'start',
    size: 'large',
    style: 'dark',
    testId: 'Button',
    type: 'button',
    variant: 'primary',
    width: 'auto'
};

export {Button};

interface WrapperProps {
    $isInverted: boolean;
    $reactsToTheme?: boolean;
    $size: ButtonSize;
    $variant: ButtonVariant;
    $width: ButtonWidth;
}

const Wrapper = styled.button<WrapperProps>`
    align-items: center;
    background-color: ${getButtonBackgroundColor('default')};
    border: none;
    border-color: ${getButtonBorderColor('default')};
    border-radius: 46px;
    border-style: solid;
    border-width: 2px;
    color: ${getButtonFontColor('default')};
    display: flex;
    font-size: ${({$size}) => (['smaller', 'small'].includes($size) ? '1.4rem' : '1.6rem')};
    font-weight: 500;
    gap: ${spacing(2)};
    height: ${getButtonHeight};
    justify-content: ${({$width}) => ($width === 'full' ? 'center' : null)};
    letter-spacing: 0.32px;
    outline: none;
    padding-block: ${({$size, theme}) => ($size === 'smaller' ? spacing(2, theme) : spacing(3, theme))};
    padding-inline: ${({$size, theme}) => ($size === 'smaller' ? spacing(8, theme) : spacing(10, theme))};
    text-align: center;
    text-decoration: none;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, height 0.3s ease-in-out, padding-inline 0.3s ease-in-out, padding-block 0.3s ease-in-out;
    width: ${getButtonWidth};
    will-change: color, background-color, border-color, height, padding-inline, padding-block;

    &:hover {
        background-color: ${getButtonBackgroundColor('hover')};
        border-color: ${getButtonBorderColor('hover')};
        color: ${getButtonFontColor('hover')};
        cursor: pointer;
    }

    &:active {
        background-color: ${getButtonBackgroundColor('active')};
        border-color: ${getButtonBorderColor('active')};
        color: ${getButtonFontColor('active')};
    }

    &:disabled {
        background-color: ${getButtonBackgroundColor('disabled')};
        border-color: ${getButtonBorderColor('disabled')};
        color: ${getButtonFontColor('disabled')};
    }

    &:focus-visible {
        background-color: ${getButtonBackgroundColor('focus')};
        border-color: ${getButtonBorderColor('focus')};
        color: ${getButtonFontColor('focus')};
        outline: 2px solid ${({theme}) => theme.colors.focusColor};
        outline-offset: 2px;
    }

`;