import {useEffect} from 'react';

import {useRouter} from 'next/router';

/**
 * Custom hook for handling smooth scrolling to anchor links on route changes.
 * This hook listens to the `routeChangeComplete` event and scrolls to the element
 * with the ID matching the URL hash, if present.
 *
 * @returns An object containing the current route without the hash.
 *
 * @example
 * ```ts
 * const {route} = useSonosLayout();
 * ```
 */
export const useSonosLayout = () => {
    const router = useRouter();
    const route = router.asPath.split('#')[0];

    useEffect(() => {
        /**
         * Handles the route change complete event and scrolls to the element with the ID
         * matching the URL hash, if present.
         *
         * @param url The new URL after the route change.
         */
        const handleRouteChangeComplete = (url: string) => {
            const hashIndex = url.indexOf('#');

            // eslint-disable-next-line @nfq/no-magic-numbers
            if (hashIndex !== -1) {
                const hash = url.substring(hashIndex + 1);

                setTimeout(() => {
                    // eslint-disable-next-line react-hooks-ssr/react-hooks-global-ssr
                    const element = document.getElementById(hash);

                    if (element) {
                        element.scrollIntoView({behavior: 'smooth'});
                    }
                }, 500);
            }
        };

        router.events.on('routeChangeComplete', handleRouteChangeComplete);

        // Clean up the event listener on component unmount
        return () => {
            router.events.off('routeChangeComplete', handleRouteChangeComplete);
        };
    }, [router.events]);

    return {route};
};