export type FontSizes = 'large' | 'medium' | 'small' | 'tiny';

interface FontSizeProps {
    $size?: FontSizes;
}

/**
 * Determines the font size based on the provided size parameter.
 * This function maps predefined size keywords to corresponding font sizes in rem units.
 * It supports three sizes: 'medium', 'small', 'tiny', and 'large', each returning a specific rem value that
 * defines the font size. The default case is 'large' if none of the specified sizes match.
 * This utility is used to dynamically set font sizes across components, ensuring consistency and the ability
 * to adjust the appearance based on the context or design requirements.
 *
 * @param props       The component props.
 * @param props.$size The size keyword that determines the font size.
 * @returns A string representing the font size in rem units.
 *
 * @example
 * ```tsx
 * const smallFontSize = fontSize({ $size: 'small' });
 * // Returns '1.6rem'
 * ```
 */
export const fontSize = ({$size}: FontSizeProps) => {
    switch ($size) {
        case 'medium':
            return '1.8rem';
        case 'small':
            return '1.6rem';
        case 'tiny':
            return '1.2rem';
        case 'large':
        default:
            return '2.1rem';
    }
};

/**
 * Determines the font weight based on the provided size parameter.
 * This function maps predefined size keywords to corresponding font weights.
 * It supports three sizes: 'small', 'tiny', 'medium', and 'large', each returning a specific font weight value.
 * Smaller sizes ('small', 'medium' and 'tiny') result in a normal weight ('400'), while 'large' results in a medium weight ('500').
 * This utility is used to ensure that text weight is consistent with its size and context within the application.
 *
 * @param props       The component props.
 * @param props.$size The size keyword that influences the font weight.
 * @returns A string representing the font weight.
 *
 * @example
 * ```tsx
 * const largeFontWeight = fontWeight({ $size: 'large' });
 * // Returns '500'
 * ```
 */
export const fontWeight = ({$size}: FontSizeProps) => {
    switch ($size) {
        case 'medium':
        case 'small':
        case 'tiny':
            return '400';
        case 'large':
        default:
            return '500';
    }
};

/**
 * Determines the line height based on the provided size parameter.
 * This function maps predefined size keywords to corresponding line heights.
 * It supports three sizes: 'medium', 'small', 'tiny', and 'large', with 'small' and 'tiny' having a line height of 1.5,
 * and 'medium', 'large' having a line height of 1.3. This utility is used to ensure optimal readability and
 * aesthetic spacing between lines of text, depending on their size. The default case is 'large' if none
 * of the specified sizes match.
 *
 * @param props       The component props.
 * @param props.$size The size keyword that influences the line height.
 * @returns A string representing the line height.
 *
 * @example
 * ```tsx
 * const tinyLineHeight = lineHeight({ $size: 'tiny' });
 * // Returns '1.5'
 * ```
 */
export const lineHeight = ({$size}: FontSizeProps) => {
    switch ($size) {
        case 'small':
        case 'tiny':
            return '1.5';
        case 'medium':
        case 'large':
        default:
            return '1.3';
    }
};