/** The width for fullscreen containers. */
export const CONTAINER_FULLSIZE_WIDTH = 1920;

/** The store links for the logos. */
export const STORE_LINKS = {
    dark: {
        ep: 'https://www.ep.de/p/1446043/ace',
        euronics: 'https://www.euronics.de/tv-und-audio/hifi-und-audio/kopfhoerer/over-ear-kopfhoerer-mit-bluetoooth/ace-bluetooth-kopfhoerer-schwarz-4065327938708',
        expert: 'https://www.expert.de/shop/unsere-produkte/tv-audio/kopfhorer/over-ear-kopfhorer/12940001004-over-ear-kopfhoerer-ace-schwarz.html',
        mediaMarktSaturn: 'https://www.mediamarkt.de/de/product/_sonos-sonos-ace-over-ear-sonos-ace-bluetooth-schwarz-2935155.html',
        tink: 'https://www.tink.de/p/sonos-ace-over-ear-kopfhoerer-mit-aktiver-geraeuschunterdrueckung'
    },
    white: {
        ep: 'https://www.ep.de/p/1446042/ace',
        euronics: 'https://www.euronics.de/tv-und-audio/hifi-und-audio/kopfhoerer/over-ear-kopfhoerer-mit-bluetoooth/ace-bluetooth-kopfhoerer-weiss-4065327938630',
        expert: 'https://www.expert.de/shop/unsere-produkte/tv-audio/kopfhorer/over-ear-kopfhorer/12940000004-over-ear-kopfhoerer-ace-weiss.html',
        mediaMarktSaturn: 'https://www.mediamarkt.de/de/product/_sonos-sonos-ace-over-ear-sonos-ace-bluetooth-weiss-2935153.html',
        tink: 'https://www.tink.de/p/sonos-ace-over-ear-kopfhoerer-mit-aktiver-geraeuschunterdrueckung?opt_92=30'
    }
};