/* eslint-disable max-len */
import React from 'react';

import styled from 'styled-components';

interface LogoProps {
    /**
     * A string representing the CSS class to be applied to the LogoIcon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A number or string representing the height of the LogoIcon element.
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the LogoIcon element.
     */
    width?: number | string;
}

/**
 * The `Logo` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `LogoProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the LogoIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the LogoIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the LogoIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const LogoComponent = <Logo className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const Logo = React.forwardRef<SVGSVGElement, LogoProps>((props, ref) => {
    const {className, color1, height, testId, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 500 100"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <G fill={color1}>
                <path d="M270.84 56.7 211.62 1.5v93.59h17.55V42.83l59.2 55.11V4.11h-17.53zM134.72.6A49.17 49.17 0 0 0 85.6 49.73a49.16 49.16 0 0 0 49.12 49.11 49.17 49.17 0 0 0 49.11-49.11A49.2 49.2 0 0 0 134.72.6m0 80.59c-17.17 0-31.14-14.11-31.14-31.47s13.97-31.47 31.14-31.47 31.15 14.12 31.15 31.47-13.97 31.47-31.15 31.47m-79-30.65C51.07 47 44.94 44.12 35.8 41.21 18 35.56 18 31.07 18 28.92c0-5.06 5.65-10.3 15.1-10.3 7.95 0 14.13 3.98 16.42 5.68l1.22.92 14.14-9.55-1.54-1.84C62.9 13.3 52.02.63 33.1.63 24 .62 15.63 3.52 9.54 8.8 3.48 14.08 0 21.41 0 28.91c0 8.07 3.52 14.85 10.46 20.14 4.67 3.56 10.8 6.44 19.94 9.34 17.78 5.65 17.78 10.14 17.78 12.28 0 5.06-5.64 10.3-15.09 10.3-7.97 0-14.14-3.97-16.42-5.68l-1.23-.91L1.3 83.94l1.54 1.83c.45.54 11.34 13.21 30.26 13.21 9.1 0 17.46-2.91 23.55-8.19 6.07-5.26 9.54-12.59 9.54-20.1 0-8.07-3.52-14.84-10.47-20.14zM365.28.97a49.17 49.17 0 0 0-49.11 49.11 49.17 49.17 0 0 0 49.11 49.12 49.2 49.2 0 0 0 49.13-49.12A49.2 49.2 0 0 0 365.28.97m0 80.58c-17.17 0-31.14-14.12-31.14-31.47 0-17.34 13.97-31.46 31.14-31.46s31.15 14.1 31.15 31.46-13.98 31.47-31.15 31.47m79-32.28c4.65 3.55 10.78 6.43 19.93 9.33C482 64.25 482 68.73 482 70.9c0 5.06-5.65 10.3-15.1 10.3-7.95 0-14.13-3.98-16.42-5.68l-1.22-.91-14.14 9.55 1.54 1.83c.45.54 11.33 13.22 30.26 13.22 9.1 0 17.46-2.91 23.55-8.19 6.06-5.27 9.54-12.59 9.54-20.1 0-8.07-3.52-14.85-10.46-20.14-4.66-3.56-10.79-6.43-19.94-9.34-17.78-5.64-17.78-10.14-17.78-12.28 0-5.06 5.64-10.3 15.09-10.3 7.97 0 14.14 3.97 16.42 5.68l1.23.92 14.14-9.56-1.53-1.83c-.45-.54-11.34-13.22-30.26-13.22-9.1 0-17.46 2.91-23.55 8.19-6.07 5.26-9.54 12.59-9.54 20.1 0 8.07 3.52 14.84 10.47 20.14z" />
            </G>
        </svg>
    );
});

Logo.displayName = 'Logo';
Logo.defaultProps = {
    className: '',
    color1: '#212325',
    height: 100,
    testId: 'Logo',
    width: 500
};

const G = styled.g`
    transition: fill 0.3s ease-in-out;
`;