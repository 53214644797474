import {AnimatePresence, m as motion} from 'framer-motion';
import Head from 'next/head';
import styled from 'styled-components';

import {Footer} from '../Footer';

import {LayoutTransition} from 'UI/animations/layout';
import {Header} from 'UI/layouts/Header';
import {useSonosLayout} from 'UI/layouts/SonosLayout/useSonosLayout';

import {ThemeStyle} from 'UI/utils/globalStyles';
import {chooseTheme} from 'UI/utils/helpers';

import type {WithChildren} from 'types/global';


/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * A boolean flag that, when true, indicates that the current page is the home page.
     */
    isHome: boolean;
    /**
     * A boolean flag that determines whether the component should react to theme changes.
     */
    isReactingToTheme: boolean;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
    /**
     * The title of the page, which is set dynamically based on the current content or section being viewed.
     */
    title?: string;
}

/**
 * Provides a layout component for the Sonos product page, integrating the header and dynamic page content.
 * The `SonosLayout` component is a fundamental structure for the Sonos product page, encapsulating the header,
 * dynamic content based on routing, and setting the document's title. It leverages the Next.js `useRouter` hook to
 * respond to route changes, animating content transitions with the `AnimatePresence` component from Framer Motion.
 * This layout ensures that the header remains consistent across different views while allowing the main content to
 * change dynamically, providing a fluid user experience as the user navigates through the site's sections.
 *
 * @param props                   The component props.
 * @param props.children          The content to be rendered within the layout. This typically includes the main body of the webpage.
 * @param props.isHome            A boolean flag that, when true, indicates that the current page is the home page.
 * @param props.testId            A unique identifier for testing purposes, aiding in the reliable targeting of the layout component in tests.
 * @param props.title             The title of the page, which is set dynamically based on the current content or section being viewed.
 * @param props.isReactingToTheme A boolean flag that determines whether the component should react to theme changes.
 * @returns A JSX.Element representing the structured layout of the page, including the header and dynamically changing content.
 *
 * @example
 * ```tsx
 * <SonosLayout testId="sonos-layout" title="Sonos Ace - Revolutionary Sound Quality">
 *     <MainSection />
 *     <TechnicalDetails />
 *     <FAQSection />
 * </SonosLayout>
 * ```
 */
const SonosLayout = ({children, isHome, isReactingToTheme, testId, title}: WithChildren<ComponentProps>) => {
    const {route} = useSonosLayout();

    return (
        <Wrapper $reactsToTheme={isReactingToTheme} data-cy={testId}>
            <Head>
                <title>{title}</title>
            </Head>
            <ThemeStyle $reactsToTheme={isReactingToTheme} />
            <Header isHome={isHome} isReactingToTheme={isReactingToTheme} />
            <AnimatePresence mode="wait">
                <Content key={route} animate="enter" exit="exit" initial="initial" variants={LayoutTransition}>
                    {children}
                </Content>
            </AnimatePresence>
            <Footer />
        </Wrapper>
    );
};

SonosLayout.displayName = 'SonosLayout';
SonosLayout.defaultProps = {
    isHome: false,
    isReactingToTheme: false,
    testId: 'SonosLayout'
};

export {SonosLayout};
interface WrapperProps {
    $reactsToTheme: boolean;
}

const Wrapper = styled.div<WrapperProps>`
    background-color: ${chooseTheme('pageBackground')};
`;


const Content = styled(motion.main)``;