/* eslint-disable security/detect-object-injection */
import {media, spacing} from '@nfq/react-grid';
import styled from 'styled-components';

import {chooseTheme} from 'UI/utils/helpers';

import type {Theme} from '@nfq/react-grid';
import type {WithChildren} from 'types/global';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    $reactsToTheme?: boolean;
    /**
     * Optional. Specifies the background color of the component. Accepts predefined color keys
     * that map to specific color values, enhancing the component's visual distinction.
     */
    bgColor?: 'blackBoxBg' | 'linenBoxBg' | 'pageBackground' | 'videoBackBg' | 'whiteBoxBg';
    /**
     * Optional. A boolean flag that, when true, removes the padding at the bottom of the component,
     * allowing for tighter spacing with subsequent content.
     */
    hasNoPaddingBottom?: boolean;
    /**
     * Optional. A boolean flag that, when true, removes the padding at the top of the component,
     * enabling closer placement to preceding content.
     */
    hasNoPaddingTop?: boolean;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * Renders a section component with customizable background color and padding options.
 * This component serves as a generic container for grouping related UI elements, providing
 * flexibility in visual styling through background color customization and the ability to adjust
 * padding at the top and bottom of the section. It accepts children components, making it versatile
 * for various content structures. The unique `testId` facilitates targeted testing, ensuring the component
 * functions as expected within different contexts and designs.
 *
 * @param props                    The component props.
 * @param props.bgColor            Optional background color of the section, allowing for visual differentiation within the layout.
 * @param props.children           The content to be rendered within the section, enabling composition of diverse UI elements.
 * @param props.hasNoPaddingBottom Optional flag to remove padding at the bottom of the section, useful for visual compactness.
 * @param props.hasNoPaddingTop    Optional flag to remove padding at the top of the section, allowing for closer alignment with preceding elements.
 * @param props.testId             A unique identifier for testing purposes, ensuring the component can be precisely targeted within test suites.
 * @param props.$reactsToTheme     Optional flag to enable theme reactivity, allowing the component to adjust its appearance based on the current theme.
 * @returns A `Wrapper` component representing a section with the specified styling and content.
 *
 * @example
 * ```tsx
 * <Section bgColor="blackBoxBg" testId="hero-section">
 *   <HeroContent />
 * </Section>
 * ```
 */
const Section = (
    {$reactsToTheme, bgColor, children, hasNoPaddingBottom, hasNoPaddingTop, testId}: WithChildren<ComponentProps>
) => (
    <Wrapper
        $bgColor={bgColor}
        $hasNoPaddingBottom={hasNoPaddingBottom}
        $hasNoPaddingTop={hasNoPaddingTop}
        $reactsToTheme={$reactsToTheme}
        data-cy={testId}
    >
        {children}
    </Wrapper>
);

Section.displayName = 'Section';
Section.defaultProps = {testId: 'Section'};

export {Section};

interface WrapperProps {
    $bgColor?: keyof Theme['colors'];
    $hasNoPaddingBottom?: boolean;
    $hasNoPaddingTop?: boolean;
    $reactsToTheme?: boolean;
}

const Wrapper = styled.section<WrapperProps>`
    background-color: ${({$bgColor}) => ($bgColor ? chooseTheme($bgColor) : chooseTheme('pageBackground'))};
    contain: paint;
    padding-block-end: ${({$hasNoPaddingBottom, theme}) => ($hasNoPaddingBottom ? null : spacing(10, theme))};
    padding-block-start: ${({$hasNoPaddingTop, theme}) => ($hasNoPaddingTop ? null : spacing(10, theme))};
    transition: background-color 0.2s ease-in-out;
    will-change: background-color;

    ${media('md')} {
        padding-block-end: ${({$hasNoPaddingBottom, theme}) => ($hasNoPaddingBottom ? null : spacing(20, theme))};
        padding-block-start: ${({$hasNoPaddingTop, theme}) => ($hasNoPaddingTop ? null : spacing(20, theme))};
    }
`;