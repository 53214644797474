import {Col, Container, Row, Spacer} from '@nfq/react-grid';
import Link from 'next/link';
import styled from 'styled-components';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * Represents the footer component of the webpage, providing links to legal pages such as the privacy policy and legal notice.
 * The `Footer` component is a critical element of the site's layout, located at the bottom of the page. It offers users easy
 * access to essential legal information through two main links: Datenschutz (Privacy Policy) and Impressum (Legal Notice).
 * This component is structured with a container that aligns its content to the right, ensuring that the links are positioned
 * appropriately within the overall layout. The use of `Spacer` between links ensures adequate spacing for readability and aesthetics.
 *
 * @param props        The component props.
 * @param props.testId A unique identifier for testing purposes, aiding in the reliable targeting of the footer component in tests.
 * @returns A JSX.Element that renders the footer of the webpage, including links to the privacy policy and legal notice.
 *
 * @example
 * ```tsx
 * <Footer testId="footer-section" />
 * ```
 */
const Footer = ({testId}: ComponentProps) => (
    <Wrapper data-cy={testId}>
        <Container>
            <RowWrapper align="center" justify={{sm: 'flex-end', xs: 'center'}}>
                <Col direction="row" xs="max-content">
                    <FooterLink href="/datenschutz">Datenschutz</FooterLink>
                    <Spacer x={20} isNotStretching />
                    <FooterLink href="/impressum">Impressum</FooterLink>
                </Col>
            </RowWrapper>
        </Container>
    </Wrapper>
);

Footer.displayName = 'Footer';
Footer.defaultProps = {testId: 'Footer'};

export {Footer};

const Wrapper = styled.footer`
    align-items: stretch;
    background-color: ${({theme}) => theme.colors.footerBgColor};
    display: flex;
    height: 9.8rem;
    width: 100%;
`;

const RowWrapper = styled(Row)`
    height: 100%;
`;

const FooterLink = styled(Link)`
    color: ${({theme}) => theme.colors.primaryFontColorLight};
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.5;
    opacity: 0.8;
    text-decoration: none;
    transition: opacity 0.3s ease-in-out;
    will-change: opacity;

    &:hover {
        opacity: 1;
    }

    &:focus-visible {
        outline: 2px solid ${({theme}) => theme.colors.focusColor};
        outline-offset: 2px;
    }
`;