import {darken, lighten, translucify} from '@nfq/react-grid';

import {chooseTheme} from 'UI/utils/helpers';

import type {DefaultTheme} from 'styled-components';

/**
 * Defines the possible sizes for buttons, affecting their height and padding.
 */
export type ButtonSize = 'normal' | 'small' | 'smaller';

/**
 * Specifies the visual appearance variant of the button, affecting its stylistic design like borders and shadows.
 */
export type ButtonVariant = 'outlined' | 'primary' | 'secondary';

/**
 * Controls the width of the button, allowing it to either match the content's width or stretch to fill its container.
 */
export type ButtonWidth = 'auto' | 'full';

/**
 * Represents the different interaction states a button can be in, each potentially altering the button's styles.
 */
type ButtonStates = 'active' | 'default' | 'disabled' | 'focus' | 'hover';

/**
 * Provides the properties for defining the width of a button.
 */
interface ButtonWidthProps {
    /**
     * Specifies the width of the button, either 'auto' for content width or 'full' for 100% width.
     */
    $width: ButtonWidth;
}

/**
 * Calculates the width of a button based on the provided width property.
 * This utility function supports responsive button designs by allowing a dynamic width setting based on the `$width` parameter.
 * The function returns '100%' if the `$width` parameter is set to 'full', enabling the button to occupy the full width of its container. Otherwise, it returns 'fit-content', allowing the button's width to adjust based on its content size.
 *
 * @param props        The function parameters.
 * @param props.$width The width property of the button, determining its width behavior.
 * @returns A string representing the CSS width value of the button, either '100%' for full-width buttons or 'fit-content' for content-adjusted width.
 *
 * @example
 * ```tsx
 * const buttonWidth = getButtonWidth({$width: 'full'});
 * // Returns '100%' for a full-width button.
 * const buttonWidth2 = getButtonWidth({$width: 'auto'});
 * // Returns 'fit-content' for a button that adjusts its width based on its content.
 * ```
 */
export const getButtonWidth = ({$width}: ButtonWidthProps) => ($width === 'full' ? '100%' : 'fit-content');

/**
 * Provides the properties for defining the background color of a button based on its state, style, and variant.
 */
interface ButtonColorProps {
    /**
     * Specifies the style of the button, influencing its visual appearance.
     */
    $isInverted: boolean;
    /**
     * Specifies the style of the button, influencing its visual appearance.
     */
    $reactsToTheme?: boolean;
    /**
     * Specifies the variant of the button, influencing the button's visual appearance.
     */
    $variant: ButtonVariant;
    /**
     * The theme object containing design tokens like spacing values.
     */
    theme: DefaultTheme;
}

/**
 * Determines the background color of a button based on its current state, style, and variant.
 * This function utilizes a mapping strategy to choose the correct background color from the theme according to the button's specifications.
 * It accounts for different button states (active, default, disabled, hover), ensuring visual consistency across different user interactions.
 *
 * @param state The current state of the button, influencing the background color.
 * @returns A function that takes button color properties and returns the corresponding CSS color value for the background.
 *
 * @example
 * ```tsx
 * const backgroundColor = getButtonBackgroundColor('hover')({
 *   $style: 'light',
 *   $variant: 'primary',
 *   theme: theme
 * });
 * ```
 */
export const getButtonBackgroundColor = (state: ButtonStates) => (
    {$isInverted, $reactsToTheme, $variant, theme}: ButtonColorProps
) => {
    const actionColor = chooseTheme('actionColor')({
        $reactsToTheme,
        theme
    });

    switch (state) {
        case 'active':
            if ($variant === 'primary') return lighten(actionColor, 10);

            return $isInverted
                ? translucify(theme.colors.secondaryInvertedButtonColor, 95)
                : translucify(theme.colors.secondaryButtonColor, 95);
        case 'disabled':
            return $variant === 'primary' ? lighten(actionColor, 65) : 'transparent';
        case 'hover':
            return $variant === 'primary' ? lighten(actionColor, 20) : 'transparent';
        case 'focus':
            return $variant === 'primary' ? chooseTheme('actionColor') : 'transparent';
        case 'default':
        default:
            return $variant === 'primary' ? chooseTheme('actionColor') : 'transparent';
    }
};

/**
 * Determines the border color of a button based on its state, style, and variant.
 * This function leverages a color mapping strategy to select the appropriate color from the theme based on the button's characteristics.
 * The mapping accounts for various states (active, default, disabled, hover) and styles (dark, light) for each button variant.
 *
 * @param state The state of the button, affecting the border color.
 * @returns A function that takes button color properties and returns a CSS color value for the border.
 *
 * @example
 * ```tsx
 * const borderColor = getButtonBorderColor('hover')({
 *   $style: 'dark',
 *   $variant: 'primary',
 *   theme: theme
 * });
 * ```
 */
export const getButtonBorderColor = (state: ButtonStates) => (
    {$isInverted, $reactsToTheme, $variant, theme}: ButtonColorProps
) => {
    const actionColor = chooseTheme('actionColor')({
        $reactsToTheme,
        theme
    });

    const secondaryInvertedButtonColor = chooseTheme('secondaryInvertedButtonColor')({
        $reactsToTheme,
        theme
    });

    const secondaryButtonColor = chooseTheme('secondaryButtonColor')({
        $reactsToTheme,
        theme
    });

    switch (state) {
        case 'active':
            if ($variant === 'primary') return lighten(actionColor, 10);

            return $isInverted
                ? darken(secondaryInvertedButtonColor, 65)
                : lighten(secondaryButtonColor, 65);
        case 'disabled':
            if ($variant === 'primary') return lighten(actionColor, 65);

            return $isInverted
                ? darken(secondaryInvertedButtonColor, 65)
                : lighten(secondaryButtonColor, 65);
        case 'hover':
            if ($variant === 'primary') return lighten(actionColor, 20);

            return $isInverted
                ? darken(secondaryInvertedButtonColor, 35)
                : lighten(secondaryButtonColor, 35);
        case 'focus':
        case 'default':
        default:
            if ($variant === 'primary') return actionColor;

            return $isInverted
                ? secondaryInvertedButtonColor
                : secondaryButtonColor;
    }
};

/**
 * Determines the text color of a button based on its state, style, and variant.
 * Similar to the `getButtonBorderColor` function, this function utilizes a mapping strategy to assign the correct text color from the theme.
 * The choice of color is influenced by the button's current state, and its style and variant, to ensure adequate contrast and visual consistency.
 *
 * @param state The state of the button, impacting the text color.
 * @returns A function that accepts button color properties and outputs a CSS color value for the text.
 *
 * @example
 * ```tsx
 * const fontColor = getButtonFontColor('default')({
 *   $style: 'light',
 *   $variant: 'secondary',
 *   theme: theme
 * });
 * ```
 */
export const getButtonFontColor = (state: ButtonStates) => (
    {$isInverted, $reactsToTheme, $variant, theme}: ButtonColorProps
) => {
    const primaryFontColorLight = chooseTheme('primaryFontColorLight')({
        $reactsToTheme,
        theme
    });

    const secondaryInvertedButtonColor = chooseTheme('secondaryInvertedButtonColor')({
        $reactsToTheme,
        theme
    });

    const secondaryButtonColor = chooseTheme('secondaryButtonColor')({
        $reactsToTheme,
        theme
    });

    switch (state) {
        case 'active':
            if ($variant === 'primary') return lighten(primaryFontColorLight, 10);

            return $isInverted
                ? darken(secondaryInvertedButtonColor, 20)
                : lighten(secondaryButtonColor, 20);
        case 'disabled':
            if ($variant === 'primary') return lighten(primaryFontColorLight, 65);

            return $isInverted
                ? darken(secondaryInvertedButtonColor, 65)
                : lighten(secondaryButtonColor, 65);
        case 'hover':
            if ($variant === 'primary') return lighten(primaryFontColorLight, 20);

            return $isInverted
                ? darken(secondaryInvertedButtonColor, 35)
                : lighten(secondaryButtonColor, 35);
        case 'focus':
        case 'default':
        default:
            if ($variant === 'primary') return primaryFontColorLight;

            return $isInverted
                ? secondaryInvertedButtonColor
                : secondaryButtonColor;
    }
};

/**
 * Provides the properties for defining the width of a button.
 */
interface ButtonHeightProps {
    /**
     * Specifies the width of the button, either 'auto' for content width or 'full' for 100% width.
     */
    $size: ButtonSize;
}

/**
 * Provides a helper function to determine the height of a button based on its size designation.
 * This function is essential in maintaining consistent button heights across different components in the application.
 * It allows for easy adjustments to the button dimensions by altering the defined cases for each size category.
 * The function supports three size categories, each returning a specific height value that conforms to the design specifications.
 *
 * @param props       The properties containing information about the button size.
 * @param props.$size The size category of the button, which influences its height. Supported values are 'small', 'smaller', and 'normal'.
 * @returns A string representing the height of the button in rem units, which ensures that the button's height scales correctly with the font size.
 *
 * @example
 * ```tsx
 * const buttonHeight = getButtonHeight({$size: 'normal'});
 * // Returns '5.6rem' for the normal size, setting a standard height for buttons in this category.
 * ```
 */
export const getButtonHeight = ({$size}: ButtonHeightProps) => {
    switch ($size) {
        case 'small':
            return '4.8rem';
        case 'smaller':
            return '3.6rem';
        case 'normal':
        default:
            return '5.6rem';
    }
};