import {Col, Container, Row, Spacer, Visible} from '@nfq/react-grid';
import Link from 'next/link';
import styled from 'styled-components';

import {Button} from 'UI/components/action/Button';

import {useHeader} from './useHeader';
import {Logo} from 'Images/icons';
import {CONTAINER_FULLSIZE_WIDTH} from 'UI/utils/CONST';
import {chooseTheme} from 'UI/utils/helpers';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The `isHome` property is an optional boolean flag that, when true, indicates that the current page is the home page.
     * This property is used to conditionally render elements based on the page's context, such as displaying additional content or modifying the layout.
     */
    isHome?: boolean;
    isReactingToTheme: boolean;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId: string;
}

/**
 * Represents the header component of a webpage, providing navigation links and a purchase button.
 * The `Header` component utilizes the `useHeader` hook to manage its visibility behavior as the user scrolls.
 * It displays the website's logo, navigational links ("Sonos Ace", "Technische Daten", "FAQ"), and a button to initiate a purchase.
 * The component's layout adjusts based on the viewport size, showing a condensed view for smaller screens and expanding the menu for larger ones.
 * It also employs the `Visible` component to conditionally render elements based on the viewport, optimizing the user experience across different devices.
 * This component encapsulates both the site's branding and essential navigation, making it a critical part of the user interface.
 *
 * @param props                   The component props.
 * @param props.testId            A unique identifier for testing purposes, ensuring the component can be reliably targeted in tests.
 * @param props.isHome            Optional. A boolean flag that, when true, indicates that the current page is the home page.
 * @param props.isReactingToTheme A boolean flag that determines whether the component should react to theme changes.
 * @returns A JSX.Element representing the structured layout of the header, including the logo, navigation links, and a purchase button.
 *
 * @example
 * ```tsx
 * <Header testId="header-component" />
 * ```
 */
const Header = ({isHome, isReactingToTheme, testId}: ComponentProps) => {
    const {headRef, isStuck, theme} = useHeader();

    return (
        <StickyBox ref={headRef} data-cy={testId}>
            <Wrapper $reactsToTheme={isReactingToTheme}>
                <Container maxWidth={CONTAINER_FULLSIZE_WIDTH}>
                    <RowWrapper align="center" justify="space-between">
                        <Col md="max-content" xs="auto">
                            <LogoLink href="/">
                                <Logo
                                    color1={chooseTheme(isStuck ? 'primaryFontColorLight' : 'actionColor')({
                                        $reactsToTheme: isReactingToTheme,
                                        theme
                                    })}
                                    height={17}
                                    width={88}
                                />
                            </LogoLink>
                        </Col>
                        {isHome && (
                            <>
                                <Visible isLoadingHtml lg md xl xxl>
                                    <Col
                                        align={{md: 'center', xs: 'flex-end'}}
                                        md="auto"
                                        order={{md: 1, xs: 2}}
                                        xs="max-content"
                                    >
                                        <MenuWrapper>
                                            <MenuLink $isStuck={isStuck} href="/">Sonos Ace</MenuLink>
                                            <Spacer maxX={20} x={5} />
                                            <MenuLink $isStuck={isStuck} href="#technical-details">
                                                Technische Daten
                                            </MenuLink>
                                            <Spacer maxX={20} x={5} />
                                            <MenuLink $isStuck={isStuck} href="#faq">FAQ</MenuLink>
                                        </MenuWrapper>
                                    </Col>
                                </Visible>
                                <Col order={{md: 2, xs: 1}} xs="max-content">
                                    <Visible isLoadingHtml lg md xl xxl>
                                        <Button
                                            as="link"
                                            href="/kaufen"
                                            isInverted={isStuck}
                                            size={isStuck ? 'smaller' : 'small'}
                                            variant="secondary"
                                        >
                                            Jetzt kaufen
                                        </Button>
                                    </Visible>
                                    <Visible isLoadingHtml sm xs>
                                        <Button
                                            as="link"
                                            href="/kaufen"
                                            isInverted={isStuck}
                                            size="smaller"
                                            variant="secondary"
                                        >
                                            Jetzt kaufen
                                        </Button>
                                    </Visible>
                                </Col>
                            </>
                        )}
                    </RowWrapper>
                </Container>
            </Wrapper>
        </StickyBox>
    );
};

Header.displayName = 'Header';
Header.defaultProps = {
    isReactingToTheme: false,
    testId: 'Header'
};

export {Header};

const StickyBox = styled.div`
    height: 1px;
    position: sticky;
    top: -1px;
    z-index: 100;
`;

interface WrapperProps {
    $reactsToTheme?: boolean;
}

const Wrapper = styled.header<WrapperProps>`
    -webkit-backdrop-filter: blur(20px);
    align-items: stretch;
    backdrop-filter: blur(20px);
    background-color: ${chooseTheme('headerBoxBg')};
    display: flex;
    height: 10rem;
    transition: height 0.3s ease-in-out, background-color 0.3s ease-in-out;
    width: 100%;
    will-change: height, background-color;
    z-index: 100;

    &.stuck {
        background-color: ${({theme}) => theme.colors.actionColor};
        height: 7.3rem;
        transition: height 0.3s ease-in-out, background-color 0.3s ease-in-out;
    }
`;

const RowWrapper = styled(Row)`
    height: 100%;
`;

const LogoLink = styled(Link)`
    display: contents;
`;

const MenuWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
`;

interface MenuLinkProps {
    $isStuck?: boolean;
    $reactsToTheme?: boolean;
}

const MenuLink = styled(Link)<MenuLinkProps>`
    color: ${chooseTheme('primaryFontColor')};
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.2;
    ${/* eslint-disable-next-line @nfq/no-magic-numbers */ ''}
    opacity: ${({$isStuck}) => ($isStuck ? 0.8 : 0.7)};
    text-decoration: none;
    transition: color 0.3s ease-in-out, opacity 0.3s ease-in-out;
    will-change: color, opacity;

    &:hover {
        opacity: 1;
    }

    &:focus-visible {
        outline: 2px solid ${({theme}) => theme.colors.focusColor};
        outline-offset: 2px;
    }

    ${Wrapper}.stuck & {
        color: ${({theme}) => theme.colors.primaryFontColorLight};
    }
`;